<template>
  <v-row justify="center" >
    <v-dialog
      v-model="dialog"
      width="90%"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          v-on="on"
          small
          @click="getData"
        >
        <v-icon>mdi-clipboard-text</v-icon>
        </v-btn>
      </template>

    <v-card>

        <v-card-title>
          <span class="text-h5 mb-5 mt-5" id="dialog_title">Bank Account</span>
        </v-card-title>

            <v-card-text>
                <v-container>
                    <AuditTrail v-if="isDataLoaded" />
                </v-container>
            </v-card-text>

            <v-card-actions class="pb-5" v-if="isDataLoaded">
                
                <v-btn
                    outlined color="primary"
                    text
                    style="float:left"
                    @click="dialog = false"
                >
                    Cancel
                </v-btn> 

                <v-spacer></v-spacer>

                <v-btn
                    class="mr-5"
                    color="error"
                    @click="disapproveDialog = true"
                    >
                    Disapprove
                </v-btn>

                <v-btn
                    class="mr-5"
                    color="success"
                    @click="approveDialog = true"
                    >
                    Approve
                </v-btn>
                
            </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="disapproveDialog"
        width="600px"
        persistent
        >
            <v-card>
                <v-card-title>
                <span class="text-h5" id="dialog_title">Disapprove</span>
                </v-card-title>
                <v-card-text>
                    <v-container>  

                        <v-row>
                            <v-col>
                                <h4>Reason for Disapproval</h4>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-textarea
                                    label="Remarks"
                                    auto-grow
                                    outlined
                                    v-model="comment"
                                ></v-textarea>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>

                <v-card-actions>

                <v-btn
                    style="float:left"
                    outlined color="primary"
                    text
                    @click="disapproveDialog = false, comment = ''"
                >
                    Cancel
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    v-if="comment != ''"
                    outlined color="red" @click="disApproveBank"
                >
                    Disapprove
                </v-btn>
                <v-btn
                    v-else
                    disabled
                >
                    Disapprove
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="approveDialog"
            persistent
            max-width="290"
            >
            <v-card>
                <v-card-title class="mb-1">
                    Confirmation
                </v-card-title>
                <v-card-text>
                    <p style="font-size: 15px;"><b>Approve Bank Details</b>?</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="approveDialog = false"
                        >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        @click="approveBank()"
                        >
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

  </v-row>
</template>

<script>

import  AuditTrail from '../AuditTrail.vue'
import { parseISO, format } from 'date-fns'
export default {
    props: ["item"],
    data () {
        return {
            dialog: false,
            isDataLoaded: false,
            approveDialog: false,
            disapproveDialog: false,
            comment: ''
        }
    },
    components:{
        AuditTrail: AuditTrail
    },
    methods: {
        async approveBank(){
            const res = await this.$store.dispatch('finance/doApproveBank', this.item)
            if (res.status == 200){
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Approved Successfully!' ,'messagetype':'success'},{ root: true });
                this.dialog = false
                this.approveDialog = false
                this.reloadData()
            } else {
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Something Went Wrong!' ,'messagetype':'error'},{ root: true });
                this.approveDialog = false
            }
        },
        async disApproveBank(){
            const payload = {
                id : this.item,
                payload: {
                    remarks: this.comment
                }
            }
            const res = await this.$store.dispatch('finance/doDisapproveBank', {payload})
            if (res.status == 200){
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Disapproved Successfully!' ,'messagetype':'success'},{ root: true });
                this.dialog = false
                this.disapproveDialog = false
                this.reloadData()
            } else {
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Something Went Wrong!' ,'messagetype':'error'},{ root: true });
                this.disapproveDialog = false
            }
        },
        async getData(){
            this.$store.commit('finance/SET_IS_DEFAULT')
            
            const res = await this.$store.dispatch('finance/doGetIsDetails', this.item)

            res.data.result.map( e => {

                if (e.sp_other_primary_details){
                    if (e.sp_other_primary_details.business_type_of_sub_agents){
                        e.sp_other_primary_details.business_type_of_sub_agents = JSON.parse(e.sp_other_primary_details.business_type_of_sub_agents)
                    }
                }

                if (e.sp_location.length != 0){
                    e.sp_location[0].mother_of_contract = JSON.parse(e.sp_location[0].mother_of_contract)
                    e.sp_location[0].moa_nonex = JSON.parse(e.sp_location[0].moa_nonex)
                    e.sp_location[0].moa_avp = JSON.parse(e.sp_location[0].moa_avp)
                }
                
                e.bank_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                e.commission_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                e.charges_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                // Commission Details
                if (e.is_commission.length != 0){
                    
                    e.is_commission.map( e=> {
                        e.created_at = this.formatDate(e.created_at)
                    })

                    let latestDate = e.is_commission.reduce((latest, current) => {
                        if (!latest || current.created_at > latest.created_at) {
                            return current;
                        } else {
                            return latest;
                        }
                    });

                    e.is_commission = [latestDate]
                }

                // Charges Details

                if (e.is_charges.length != 0){
                    let newArr = []
                    e.is_charges.map( e=> {
                        if (e.tran_type == 'COM'){
                            newArr.push(e)
                        }
                    })
                    newArr.map( e=> {
                        e.created_at = this.formatDate(e.created_at)
                    })
                    let latestDate = newArr.reduce((latest, current) => {
                        if (!latest || current.created_at > latest.created_at) {
                            return current;
                        } else {
                            return latest;
                        }
                    });
                    e.is_charges = [latestDate]
                }


                if (e.is_def_bank_accounts){
                    e.is_def_bank_accounts.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }
                    })
                }

                if (e.is_commission){
                    e.is_commission.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }
                    })
                }

                if (e.is_charges){
                    e.is_charges.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }

                        if (e.charge_rate){
                            e.charge_rate = parseInt(e.charge_rate)
                        }
                    })
                }

                // Branch Details
                if (e.sp_branch.data.length != 0){
                    e.sp_branch.data.map( e => {
                        if (e.pc_equip == 'Y' || e.pc_equip == 'N'){
                            e.pc_equip = 0
                        } else if (typeof e.pc_equip == 'string'){
                            parseInt(e.pc_equip)
                        }
                    })
                }

            })
            this.$store.commit('finance/SET_IS_DETAILS', res.data.result[0])
            this.isDataLoaded = true;
        },
        formatDate (date) {
            return format(parseISO(date), 'yyyy-MM-dd')
        },
        reloadData(){
            location.reload();
        }
    }

}
</script>
